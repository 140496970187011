import React from "react";

const Address = () => {
  return (
    <ul>
      
      {/* End li */}

      <li>
        <img className="svg" src="img/svg/mail.svg" alt="" />
        <span>
          <a href="mailto:hello@przemyslawkasiura.com">hello(at)przemyslawkasiura.com</a>
        </span>
      </li>
      {/* End li */}

    </ul>
  );
};

export default Address;
