import React from "react";
import Skills from "../skills/SkillsAnimation";

const AboutDarkAnimation = () => {
  return (
    <>
      <div className="about_inner">
        <div className="left">
          <img src="img/thumbs/1-1.jpg" alt="" />
          <div
            className="image"
            data-aos="fade-right"
            data-aos-duration="1200"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/about/omnie.jpg"
              })`,
            }}
          ></div>
        </div>
        <div
          className="right"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="50"
        >
          <div className="short">
            <h3>I'm Przemyslaw Kasiura</h3>
            <h5>
              Experienced <span className="theme-color">T-Shaped Marketer </span>
              based in <span className="theme-color">Poznań, Poland</span>
            </h5>
            <p>
            If you are looking for a specialist in Social Media advertising, 
            or you need a website from scratch on Wordpress, or you simply need graphics 
            (including DTP), you have come to the right place. Get to know me better and 
            see what I can do for you.
            </p>
          </div>
          <div className="extra">
            <h3 className="title">Personal Info</h3>
            <div className="list">
              <ul>
                <li>
                  <p>
                    <span>Name:</span> Przemyslaw Kasiura
                  </p>
                </li>
                <li>
                  <p>
                    <span>Address:</span> Poznań, Poland
                  </p>
                </li>
                <li>
                  <p>
                    <span>Born:</span> 1991
                  </p>
                </li>
                <li>
                  <p>
                    <span>Languages:</span> Polish, English
                  </p>
                </li>
                <li>
                  <p>
                    <span>Email:</span> hello(at)przemyslawkasiura.com
                  </p>
                </li>
              </ul>
            </div>
            
            <h3 className="title">Certificate</h3>
            <div className="list">
              <ul>
                <li>
                  <p>
                    <span>Name:</span> 410-101: Meta Certified Media Buying Professional
                  </p>
                </li>
                <li><div className="beny_tm_button color-outline">
              <a href="img/about/Przemyslaw_Kasiura_cv.pdf" download>
                <span className="wrapper">
                  <span className="first">Download CV</span>
                  <span className="second">Download CV</span>
                </span>
              </a>
            </div></li>
                <li>
                <div className="badge">
                  <a href="https://www.credly.com/badges/f2b82bc2-49bb-4df4-8083-1fb6b0cf6467/public_url">
                  <img src={"img/about/meta_badge.png"}></img></a>
                </div>
                </li>
               
              </ul>
             </div>
            {/* End list */}
            
          </div>
        </div>
      </div>
      <Skills />
    </>
  );
};

export default AboutDarkAnimation;
