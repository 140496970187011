import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const educationContent = [
  
  {
    passingYear: "2013-2015",
    degree: "Economy / MA",
    instituteName: "Poznan University of Economics and Business",
  },
  {
    passingYear: "2010-2013",
    degree: "European Studies / BA",
    instituteName: "University of Łódź, Poland",
  },
];

const skillsContent = [
  {
    name: "SM paid campaigns",
    skillPercent: "95",
  },
  {
    name: "InDesign",
    skillPercent: "80",
  },
  {
    name: "Photoshop",
    skillPercent: "85",
  },
  {
    name: "WordPress",
    skillPercent: "75",
  },
  {
    name: "SM organic",
    skillPercent: "85",
  },
];

const awardContent = [
  {
    awardYear: "2023",
    degree: "SKVOT",
    instituteName: "Social Media Online Course",
  },
  {
    awardYear: "2023",
    degree: "AvaTrade",
    instituteName: "Social Media campaigns",
  },
  {
    awardYear: "2023",
    degree: "Supuppy",
    instituteName: "Wordpress Development",
  },
];
const experienceContent = [
  {
    designation: "Freelancer",
    jobType: "Contract B2B | Remote",
    year: "2021 - Present",
    compnayName: "Sole trader",
    descriptions:
      "Working with many clients from various categories - from B2B businesses, such as the oil & gas industry, to B2C (like e-commerce). I'm doing my best to be a great advisor, supervisor, and/or executor in the field of Social Media marketing, website development, and UI/UX design.  ",
    animationDealy: "",
  },
  {
    designation: "Social Media Lecturer",
    jobType: "Contract B2B | Remote",
    year: "2023 - Present",
    compnayName: "SKVOT",
    descriptions:
      "I conduct long-term online courses in the field of Social Media Marketing. Each stream has a 3-month length and contains approximately 50 to 80 participants. I'm a co-host with Marta Janus, the Social Media Team leader of Lidl Polska. ",
    animationDealy: "",
  },
  {
    designation: "Social Media Lecturer",
    jobType: "Contract B2B | Remote",
    year: "2021 - Present",
    compnayName: "Atrium Marketing Academy",
    descriptions:
      "I conduct training in the field of Social Media Marketing. These are open and closed classes. As part of open training, I meet the widest possible range of people with different experiences in internet marketing. In the case of closed training, I had the pleasure of working, among others with universities and embassies.",
    animationDealy: "",
  },
  {
    designation: "Marketing Director",
    jobType: "Part-time | Remote",
    year: "2019 - Present",
    compnayName: "Top Level Tennis",
    descriptions:
      "I am responsible for the comprehensive development of the project from the marketing and sales side. My primary task is to maintain constant, effective and multi-channel communication based on the available marketing budget.",
    animationDealy: "200",
  },
  {
    designation: "Social Media Manager",
    jobType: "Full Time | InHouse",
    year: "2018 - 2020",
    compnayName: "GoldenSubmarine - Marketing Agency",
    descriptions:
      "Planning and implementation of communication strategies in Social Media, optimization of Facebook, Twitter, Linkedin, and Instagram campaigns, moderating Facebook profiles and managing group of moderators, cooperating with such Clients like Grupa Żywiec, Żabka Polska, PayU, and Citi Group.",
    animationDealy: "",
  },
  {
    designation: "Marketing Comms Manager",
    jobType: "Full Time | InHouse",
    year: "2015 - 2018",
    compnayName: "Business Sport Solutions - Sports Marketing Agency",
    descriptions:
      "Planning communication strategy for client's product or service, designing online and printed content and graphics, driving or co-driving client's Social Media channels (clients from the retail sector, banking, and fuel industry), co-planning and developing post schedules, PR, and online marketing for dedicated projects.",
    animationDealy: "200",
  },
];

const SkillsAnimation = () => {
  return (
    <>
      <div className="beny_tm_resume">
        <div className="main_title">
          <h3>My Experience</h3>
        </div>
        <Tabs>
          <TabList className="tab_list">
            <Tab> Education & Skills</Tab>
            <Tab>Experience</Tab>
          </TabList>
          <TabPanel>
            <div className="resume_inner">
              <div className="row">
                <div
                  className="col-4"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="education ">
                    <div className="edu_list">
                      <h4>
                        <label>Education</label>
                      </h4>
                      <ul>
                        {educationContent.map((val, i) => (
                          <li key={i}>
                            <span className="year">{val.passingYear}</span>
                            <p>
                              <span>{val.degree}</span> - {val.instituteName}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* End .col-4 */}

                <div
                  className="col-4"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-delay="200"
                >
                  <div className="skills ">
                    <h4>
                      <label>Skills</label>
                    </h4>
                    <div className="beny_progress">
                      {skillsContent.map((val, i) => (
                        <div className="progress_inner" key={i}>
                          <span>
                            <span className="label">{val.name}</span>
                            <span className="number">{val.skillPercent}%</span>
                          </span>
                          <div className="background">
                            <div className="bar open">
                              <div
                                className="bar_in"
                                style={{ width: val.skillPercent + "%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* End .col-5 */}

                <div
                  className="col-4"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-delay="400"
                >
                  <div className="education ">
                    <div className="edu_list">
                      <h4>
                        <label>Last projects</label>
                      </h4>
                      <ul>
                        {awardContent.map((val, i) => (
                          <li key={i}>
                            <span className="year">{val.awardYear}</span>
                            <p>
                              <span>{val.degree}</span> - {val.instituteName}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* End .col-4 */}
              </div>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="row">
              {experienceContent.map((val, i) => (
                <div
                  className="col-6"
                  key={i}
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-delay={val.animationDealy}
                >
                  <div className="resume-box-01">
                    <h5>{val.designation}</h5>
                    <h6>{val.jobType}</h6>
                    <span>{val.year}</span>
                    <div className="hr"></div>
                    <h4>
                      <label>{val.compnayName}</label>
                    </h4>
                    <p>{val.descriptions}</p>
                  </div>
                </div>
                //  End .col
              ))}
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default SkillsAnimation;
